body, #root, #app, #app>div {
  min-height: 100vh;
}

body {
  background-color: #83EAF1; /* fallback for old browsers */
  /* background: -webkit-linear-gradient(to right, #83EAF1, #63A4FF); */
  /* background: linear-gradient(to right, #83EAF1, #63A4FF); */
  /* background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%); */
  background: url("../public/background.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.SUCCESS-log {
  color: #43eb3b;
}

.INFO-log {
  color: #4db5ff;
}

.ERROR-log {
  color: #ff5340;
}

.logs-container {
  background-color: #212121 !important;
  color: white !important;
  border: 4px solid black;
}

.logs-container-divider {
  background-color: grey !important;
}

.color-green {
  background-color: #45ff70;
}

.color-red {
  background-color: #ff6961;
}

.color-cyan {
  background-color: #6be6ff;
}

.sidebar-margin {
  margin-left: 240px;
}

.algo-page-card {
  box-shadow: none !important;
}